<template>
    <div>
      <!-- <button @click="toggleWeekends">toggle weekends</button> -->
      <FullCalendar ref="calendar"  :options="calendarOptions" @change="refreshEvents()"  />

      

    </div>
</template>
<script>

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es';

export default {
    name: 'Calendar',
    props: {
        events: {
            type: Array,
            required: true
        },
    },
    components: {
        FullCalendar, // make the <FullCalendar> tag available
    },

    data() {
        return {
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: 'dayGridMonth',
                selectable: true,
                // editable: true,
                // dateClick: this.handleDateClick,
                // weekends: false, // initial value
                locale: esLocale,
                events:this.events,
                // events: [
                //     { title: 'event 1', start: '2022-10-05 12:00:00', end: '2022-10-05 15:30:00', color: 'yellow', textColor: 'black' },
                //     { title: 'event 2', start: '2022-10-05 12:00:00', end: '2022-10-05 15:30:00', color: 'red', textColor: 'black' },
                //     // { title: 'event 2', date: '2022-10-11' }
                // ],
                headerToolbar: {
                    left: 'dayGridMonth timeGridWeek timeGridDay',
                    center: 'title',
                    right: 'custom1 prevYear prev next nextYear'
                },
                customButtons: {
                    custom1: {
                        text: 'Nuevo Contacto',
                        click: this.abreModal,
                    },
                    // custom2: {
                    //     text: 'custom 2',
                    //     click: function() {
                    //         alert('clicked custom button 2!');
                    //     }
                    // }
                },
                eventClick: this.editaEvento,
                // eventClick: function(info) {
                //     //console.log(info.event);
                //     info.jsEvent.preventDefault();
                //     // alert('Event: ' + info.event.title);
                //     //alert('Id: ' + info.event.id);
                //     this.editaEvento(info);
                //     // alert('Start: ' + info.event.startStr + ' - ' + info.event.endStr);
                //     // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                //     // alert('View: ' + info.view.type);

                //     // change the border color just for fun
                //     //info.el.style.borderColor = 'red';
                // },
                eventDidMount: function(info) {
                    // console.log(info.event.extendedProps);
                    // {description: "Lecture", department: "BioChemistry"}
                    var tooltip = new Tooltip(info.el, {
                        title: info.event.extendedProps.description,//you can give data for tooltip
                        placement: 'top',
                        trigger: 'hover',
                        container: 'body'
                    });
                },
                // eventRender: function(info) {
                //     var tooltip = new Tooltip(info.el, {
                //     title: info.event.extendedProps.description,
                //     placement: 'top',
                //     trigger: 'hover',
                //     container: 'body'
                //     });
                // },
            }
        } 
    },

    methods: {
        handleDateClick: function(arg) {
          alert('date click! ' + arg.dateStr)
        },
        toggleWeekends: function() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
        },
        abreModal: function() {
            this.$emit('nuevo-evento', true);
        },
        editaEvento: function(arg) {
            // console.log(arg.event.id);
            this.$emit('recuperaEvento', arg.event.id);
        },

        
    },
    created () {
      // this.recuperaDatos();
    },
    computed: {
      eventos() {
        return this.events; 
      }
    },
    // watch: {
    //   events(newValue, oldValue) {
    //     console.log('acx');
    //     // this.$refs.calendar.$emit('refetchEvents')
    //   }
    // },

  
  }  
</script>



<style lang="scss" scoped>

  .fc-event, .fc-event-dot {
      background-color: #D1DADE !important;
  }
    .event {
      background-color:#00FF00;
  }

  .greenEvent {
      background-color:#00FF00;
  }

  .redEvent {
      background-color:#FF0000;
  }
</style>