import './bootstrap';

import { createApp } from 'vue';


import router from './routes/index'

const app = createApp({});

// app.component('calendar-component', require('./components/Calendar/CalendarComponent.vue').default);
// app.component('modal-component', require('./components/Modal/ModalComponent.vue').default);
app.component('home', require('./components/HomeComponent.vue').default);
app.component('users', require('./components/User/UserComponent.vue').default);

app.use(router);

app.mount('#app');
