<template>
    <div>

        <div>
            <span class="badge m-1" style="background-color: #1D84C6">Agendado</span>
            <span class="badge m-1" style="background-color: #F8AC5A">No Contesta</span>
            <span class="badge m-1" style="background-color: #ED5666">Rechazo</span>
            <span class="badge m-1" style="background-color: #0A28FE">Reagenda</span>
            <span class="badge m-1" style="background-color: #1BB394">Realizada</span>
        </div>

        <calendar v-if="events" :events="events" 
            @nuevo-evento="nuevoEvento" 
            @recuperaEvento="recuperaEvento" 
        ></calendar>

        

        <FormComponent 
            @nuevo-evento="nuevoEvento"
            @refrescaGrafico="refrescaGrafico"
            variant="primary" 
            :msg="msg" 
            :visible="visible"
            :idRecuperacion="idRecuperacion"
        >
        </FormComponent>
    </div>
</template>

<script>
    import Calendar from './Calendar.vue'
    import FormComponent from './FormComponent.vue'

    export default {
        components: {
            Calendar,
            FormComponent,
        },
        data() {
            return {
                visible: false,
                msg: 'sdad asdasd',
                events: null,
                idRecuperacion: null,
            }
        },
        methods: {
            nuevoEvento(visible) {
                console.log(visible);
                this.idRecuperacion = null,
                this.visible = visible;
                this.recuperaDatos();
            },
            refrescaGrafico(){
                console.log('refresca');
                this.recuperaDatos();
                window.location.href = "/"
            },  
            recuperaDatos(){
                axios.get('agenda')
                .then((response) => {
                    this.events = response.data.data;
                })
            },
            recuperaEvento(id){
                this.idRecuperacion = id;
            }
        },
        created () {
            this.recuperaDatos();
        },
    }
</script>

<style lang="scss" scoped>


    
</style>