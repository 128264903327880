<template>
    <div>
        
        <div v-if="OpenClose" class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" style="display:block">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Nuevo Contacto</h5>
                        <button type="button" @click="OpenCloseFun()" class="btn-close" ></button>
                    </div>
                    <form @submit.prevent="editmode ? updateAgenda() : createAgenda()" @keydown="form.onKeydown($event)">
                        <div class="modal-body">
                            <div class="row mb-3">
                                <div class="col-md-2">
                                    <label for="id" class="form-label">id</label>
                                    <input v-model="form.id" @change="recuperaDatos(form.id)" class="form-control" id="id" type="number" aria-label="default input example">
                                </div>
                                
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <input v-model="form.telefono" type="text" class="form-control" placeholder="Teléfono" aria-label="Teléfono" disabled>
                                </div>
                                <div class="col">
                                    <input v-model="form.email" type="text" class="form-control" placeholder="Mail" aria-label="Mail" disabled>
                                </div>
                                <div class="col">
                                    <input v-model="form.nombre" type="text" class="form-control" placeholder="Nombre" aria-label="Nombre" disabled>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <input v-model="form.emisor" type="text" class="form-control" placeholder="Emisor" aria-label="Emisor" disabled>
                                </div>
                                <div class="col">
                                    <input v-model="form.cargo_cliente" type="text" class="form-control" placeholder="Cargo del cliente" aria-label="Cargo del cliente" disabled>
                                </div>
                                <div class="col">
                                    <input v-model="form.categoria_del_cargo" type="text" class="form-control" placeholder="Categoría del cargo" aria-label="Categoría del cargo" disabled>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <label for="id" class="form-label">Fecha</label>
                                    <input v-model="form.fecha" class="form-control" :disabled="!activaForm" type="date" id="birthday" name="birthday" required :class="{ 'is-invalid': form.errors.has('fecha') }"/>
                                    <div class="invalid-feedback" v-if="form.errors.has('fecha')" v-html="form.errors.get('fecha')" />
                                </div>
                                <div class="col">
                                    <label for="id" class="form-label">Hora</label>
                                    <!-- <input v-model="form.horario_id" type="text" class="form-control" placeholder="Categoría del cargo" aria-label="Categoría del cargo"> -->
                                    <select v-model="form.horario_id" :disabled="!activaForm" class="form-select" aria-label="Default select example" required :class="{ 'is-invalid': form.errors.has('horario_id') }">
                                        <option value="">Seleccione</option>
                                        <option value="1">09:30:00 - 10:00:00</option>
                                        <option value="2">10:00:00 - 10:30:00</option>
                                        <option value="3">10:30:00 - 11:00:00</option>
                                        <option value="4">11:00:00 - 11:30:00</option>
                                        <option value="5">11:30:00 - 12:00:00</option>
                                        <option value="6">12:00:00 - 12:30:00</option>
                                        <option value="7">12:30:00 - 13:00:00</option>
                                        <option value="8">13:00:00 - 13:30:00</option>
                                        <option value="9">13:30:00 - 14:00:00</option>
                                        <option value="10">14:00:00 - 14:30:00</option>
                                        <option value="11">14:30:00 - 15:00:00</option>
                                        <option value="12">15:00:00 - 15:30:00</option>
                                        <option value="13">15:30:00 - 16:00:00</option>
                                        <option value="14">16:00:00 - 16:30:00</option>
                                        <option value="15">16:30:00 - 17:00:00</option>
                                        <option value="16">17:00:00 - 17:30:00</option>
                                        <option value="17">17:30:00 - 18:00:00</option>
                                        <option value="18">18:00:00 - 18:30:00</option>
                                        <option value="19">18:30:00 - 19:00:00</option>
                                        <option value="20">19:00:00 - 19:30:00</option>
                                        <option value="21">19:30:00 - 19:00:00</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="form.errors.has('horario_id')" v-html="form.errors.get('horario_id')" />
                                </div>
                                
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <label for="id" class="form-label">Observación</label>
                                    <textarea v-model="form.observacion" :disabled="!activaForm" class="form-control" id="exampleFormControlTextarea1" rows="3" required :class="{ 'is-invalid': form.errors.has('observacion') }"></textarea>
                                    <div class="invalid-feedback" v-if="form.errors.has('observacion')" v-html="form.errors.get('observacion')" />
                                </div>
                                <div class="col">
                                    <label for="id" class="form-label">Estado</label>
                                    <select v-model="form.estado_id" :disabled="!activaForm" class="form-select" aria-label="Default select example" required :class="{ 'is-invalid': form.errors.has('estado_id') }">
                                        <option value="">Seleccione</option>
                                        <option value="1">Agendado</option>
                                        <option value="2">No contesta</option>
                                        <option value="3">Rechazo</option>
                                        <option value="4">Reagenda</option>
                                        <option value="5">Realizada</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="form.errors.has('estado_id')" v-html="form.errors.get('estado_id')" />
                                </div>
                                
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button"  @click="OpenCloseFun()" class="btn btn-secondary" >Close</button>
                            <button v-if="activaForm && !editmode" type="submit" class="btn btn-primary" >Crear</button>
                            <button v-if="activaForm && editmode" type="submit" class="btn btn-primary" >Actualizar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Form from 'vform'

    export default {
        components: {  },
        name: 'FormComponent',
        props: {
            visible: Boolean,
            variant:String,
            idRecuperacion: String,
        },
        data(){
            return{
                OpenClose : this.visible,
                editmode: false,
                activaForm: false,
                form: new Form({
                    id: '',
                    telefono: '',
                    email: '',
                    nombre: '',
                    emisor: '',
                    cargo_cliente: '',
                    categoria_del_cargo: '',
                    fecha: '',
                    horario_id: '',
                    observacion: '',
                    estado_id: ''
                })
            }
        },
        methods:{
            OpenCloseFun(){
                console.log(12);
                this.form.reset();
                this.activaForm = false;
                this.OpenClose = false;
                this.$emit('nuevo-evento', false);
            },
            recuperaDatos(id){
                this.form.telefono = '';
                this.form.email = '';
                this.form.nombre = '';
                this.form.emisor = '';
                this.form.cargo_cliente = '';
                this.form.categoria_del_cargo = '';
                this.form.fecha = '';
                this.form.horario_id = '';
                this.form.observacion = '';
                this.form.estado_id = '';

                axios.get('api/tabla-datos/' + id)
                .then((response) => {
                    if (response.data.data.dato.length > 0){
                        this.form.fill(response.data.data.dato[0]);
                        this.editmode = response.data.data.editmode;
                        this.activaForm = response.data.data.valida;
                    }
                })
                .then(() => {
                    if (!this.activaForm){
                        alert('No existe');
                    }
                })
            },
            createAgenda(){
                this.form.post('agenda')
                .then((response) => {
                    this.OpenClose = false;
                    this.$emit('refrescaGrafico');
                });
            },
            updateAgenda(){
                console.log('actualiza');
                this.form.put('agenda/' + this.form.id)
                .then((response) => {
                    this.OpenClose = false;
                    this.$emit('refrescaGrafico');
                });
            }
        },
        watch: { 
            visible: function(newVal, oldVal) { // watch it
                this.OpenClose = newVal;
            },
            idRecuperacion: function(newVal, oldVal) { 
                if (newVal){
                    this.recuperaDatos(newVal);
                    this.form.id = newVal;
                    this.OpenClose = true;
                }
                
            }, 
        }

    }
</script>

<style lang="scss" scoped>

</style>