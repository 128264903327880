import { createRouter, createWebHistory } from "vue-router";

import HomeComponent from '../components/HomeComponent.vue';
import AboutComponent from '../components/AboutComponent.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeComponent
    },
    {
        path: '/about',
        name: 'about',
        component: AboutComponent
    },
]


const route = createRouter({
    history: createWebHistory(),
    routes
});

export default route;
